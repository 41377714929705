var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tab-content"},[_c('table',{staticClass:"table table-striped brand draft-list"},[_vm._m(0),_c('tbody',_vm._l((_vm.deletedQuizzes.slice(0, _vm.limit)),function(quiz){return _c('RowData',{key:quiz.id,attrs:{"name":quiz.name,"dateLeft":`Deleted: ${Math.ceil(
						Number(new Date() - new Date(quiz.deleted_at)) /
							1000 /
							60 /
							60 /
							24
					)} days ago`}})}),1)]),(_vm.deletedQuizzes.length > _vm.limit)?_c('v-btn',{staticClass:"admin-primary-button secondary-contrast-background",on:{"click":_vm.loadMoreQuizzes}},[_vm._v("Load More")]):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',{staticClass:"col-2"}),_c('th',{staticClass:"col-3"},[_vm._v("Quize Title")]),_c('th',{staticClass:"col-2 text-center"},[_vm._v("Date Created")]),_c('th',{staticClass:"col-6"})])])
}]

export { render, staticRenderFns }