<template>

<div class="tab-content">
		<table class="table table-striped brand draft-list">
			<thead>
				<tr>
					<th class="col-2"></th>
					<th class="col-3">Quize Title</th>
					<th class="col-2 text-center">Date Created</th>
					<th class="col-6"></th>
				</tr>
			</thead>
			<tbody>

				<RowData
					v-for="quiz in deletedQuizzes.slice(0, limit)"
					:key="quiz.id"
					:name="quiz.name"
					:dateLeft="`Deleted: ${Math.ceil(
						Number(new Date() - new Date(quiz.deleted_at)) /
							1000 /
							60 /
							60 /
							24
					)} days ago`"
				/>

			</tbody>
		</table>

		<v-btn
					v-if="deletedQuizzes.length > limit"
					class="admin-primary-button secondary-contrast-background"
					@click="loadMoreQuizzes"
					>Load More</v-btn
				>
	</div>

	<!-- <v-container>
		<v-row>
			<v-col>
				<admin-default-subheader
					>Deleted Quizes</admin-default-subheader
				>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<RowData
					v-for="quiz in deletedQuizzes.slice(0, limit)"
					:key="quiz.id"
					:name="quiz.name"
					:dateLeft="`Deleted: ${Math.ceil(
						Number(new Date() - new Date(quiz.deleted_at)) /
							1000 /
							60 /
							60 /
							24
					)} days ago`"
				/>
			</v-col>
		</v-row>
		<v-row>
			<v-col class="d-flex flex-column align-center">
				<v-btn
					v-if="deletedQuizzes.length > limit"
					class="admin-primary-button secondary-contrast-background"
					@click="loadMoreQuizzes"
					>Load More</v-btn
				>
			</v-col>
		</v-row>
	</v-container> -->
</template>

<script>
import AdminDefaultSubheader from "@/components/AdminDefaultSubheader.vue";
import { mapGetters } from "vuex";

export default {
	name: "CoursesDeleted",
	components: { AdminDefaultSubheader },
	computed: {
		...mapGetters(["deletedQuizzes"])
	},
	data() {
		return {
			limit: 2
		};
	},
	methods: {
		loadMoreQuizzes() {
			this.limit = this.deletedQuizzes.length;
		}
	}
};
</script>

<style></style>
